import styles from '../styles/components/Emersion.module.scss';

import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllEmersion, removeEmersion } from '../redux/taskActions';
import { useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../hooks/useWindowDimensions';

export const Emersion = () => {
	const { height } = useWindowDimensions();
	const elementRef = useRef(null);
	const [componentHeight, setComponentHeight] = useState(null);
	const dispatch = useDispatch();
	const emersionStore = useSelector(state => state.task.emersions);

	useEffect(() => {
		const elementCount = 46 * emersionStore.length;
		const containerHeight = height - 140;

		setComponentHeight(elementCount > containerHeight ? containerHeight : null);
	}, [
		height,
		emersionStore,
		componentHeight,
		setComponentHeight
	]);

	const onCloseAllHandler = () => {
		dispatch(removeAllEmersion());
	}
	const onCloseItemHandler = (index) => {
		dispatch(removeEmersion(index));
	}

	return (
		<>
			{emersionStore.length > 0 && (
				<div className={styles.emersion} style={{height: `${componentHeight}px`}}>
					<button onClick={onCloseAllHandler} className={styles.clear}>Clear All</button>

					{emersionStore.map(({ message, type }, index) => (
						<div ref={elementRef} key={index} className={classnames(styles.container, styles[type])}>
							{message}

							<button onClick={() => {onCloseItemHandler(index)}} className={styles.close}>x</button>
						</div>
					))}
				</div>
			)}
		</>
	);
}