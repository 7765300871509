import styles from '../styles/Global.module.scss';
import { ButtonsGame } from '../components/buttons/ButtonsGame';

export const Games = () => {
	return (
		<div className={styles.games}>
			<h1 className={styles.title}>Choose the Game</h1>
			<p className={styles.description}>Click the button and select a pair to connect.</p>

			<ButtonsGame start={true} />
		</div>
	);
}