const SERVER = 'http://localhost';

export const emersionMessage = (message, type = 'log') => {
	return {
		message,
		type
	}
}

export const bikeConnectionData = (status, name, index) => {
	return {
		status,
		name,
		index
	}
}

export const STORAGE = {
	ip: 'controller_ip',
	gameType: 'game_type'
}

export const INTERVALS = {
	battleInterval: '',
	distanceInterval: ''
};

export const MONTH_NAMES = [
	'January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December'
];

export const SERVER_DATA = {
	users: SERVER + '/users/',
	post:  SERVER + '/users/add',
	getByType: (type) => { return SERVER + '/users/game-type/' + type },
	getById: (id) =>     { return SERVER + '/users/' + id },
	getByAge: (age) =>   { return SERVER + '/users/age/' + age },
	update: (id) =>      { return SERVER + '/users/' + id + '/update' },
	delete: (id) =>      { return SERVER + '/users/' + id + '/delete' }
};

export const TIME = {
	time: {
		min: '00',
		sec: '30',
		mil: '000'
	}
}

export const BATTLE_TIME = {
	min: 0,
	sec: 30,
	mil: 0
}

export const DISTANCE_TIME = {
	min: 0,
	sec: 20,
	mil: 0
}

export const EMAIL_REGEXP = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const IP_REGEXP = '^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)\\.?\\b){4}$';
export const FORM_DATA = [
	{ name: 'Name',
		id: 'name',
		type: 'text',
		value: '',
		view: 'half',
		validation: {
			required: {
				value: true,
				message: 'Please Enter Valid Name',
			},
			maxLength: {
				value: 30,
				message: '30 characters max',
			},
		}
	},
	{ name: 'Surname',
		id: 'surname',
		type: 'text',
		value: '',
		view: 'half',
		validation: {
			required: {
				value: true,
				message: 'Please Enter Valid Surname',
			},
			maxLength: {
				value: 30,
				message: '30 characters max',
			},
		}
	},
	{ name: 'Email',
		id: 'email',
		type: 'email',
		value: '',
		validation: {
			pattern: {
				value:
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: 'Please Enter Valid Email Address',
			},
		}
	},
	{ name: 'Phone number',
		id: 'phone',
		type: 'text',
		error: 'Please Enter Valid Phone number',
		value: '',
		view: 'half'
	},
	{ name: 'Age group',
		id: 'age_group',
		type: 'select',
		value: '',
		view: 'half',
		age_group: [
			{ name: '19 and under', value: '19' },
			{ name: '20-24', value: '20-24' },
			{ name: '25-34', value: '25-34' },
			{ name: '35-44', value: '35-44' },
			{ name: '45-54', value: '45-54' },
			{ name: '55-64', value: '55-64' },
			{ name: '65-74', value: '65-74' },
			{ name: '75-84', value: '75-84' },
		],
		validation: {
			required: {
				value: true,
				message: 'Please Choose Age group'
			}
		}
	},
	{ name: 'Gender',
		id: 'gender',
		type: 'radio',
		value: '',
		radio: [
			{ name: 'Male', value: 'male' },
			{ name: 'Female', value: 'female' }
		],
		validation: {
			required: {
				value: true,
				message: 'Please Check Gender'
			}
		}
	},
	{ name: 'Agreement',
		id: 'agreement'	,
		type: 'checkbox',
		validation: {
			required: {
				value: true,
				message: 'Please Check Agreement checkbox'
			}
		}
	},
]