import {
	ADD_EMERSION_MESSAGE, ADMIN_LINK, CONNECTION_STATUS, CONTROLLER_STATUS, GAME_TYPE,
	REMOVE_ALL_EMISION, REMOVE_EMISION_MESSAGE
} from './tasActionTypes';

export const setGameType = (type) => {
	return {
		type: GAME_TYPE,
		payload: type
	}
}
export const connectionStatus = (value) => {
	return {
		type: CONNECTION_STATUS,
		payload: value
	}
}

export const controllerStatus = (value) => {
	return {
		type: CONTROLLER_STATUS,
		payload: value
	}
}

export const addEmersion = (value) => {
	return {
		type: ADD_EMERSION_MESSAGE,
		payload: value
	};
}

export const removeEmersion = (index) => {
	return {
		type: REMOVE_EMISION_MESSAGE,
		payload: index
	};
}

export const removeAllEmersion = () => {
	return {
		type: REMOVE_ALL_EMISION
	};
}

export const setCurrentLink = (link) => {
	return {
		type: ADMIN_LINK,
		payload: link
	};
}