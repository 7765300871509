import styles from '../styles/components/Controls.module.scss';
import { useLocation } from 'react-router';

import { ButtonsGame } from './buttons/ButtonsGame';
import { ButtonsControl } from './buttons/ButtonsControl';
import { ButtonsNewGame } from './buttons/ButtonsNewGame';
export const Controls = () => {
	const location = useLocation();
	const page = location.pathname.split('/')[1];
	const isResults = page === 'results';

	return (
		<footer className={styles.footer}>
			{location.pathname !== '/' && (
				<ButtonsGame />
			)}

			{isResults ? (
				<ButtonsNewGame />
			) : (
				<ButtonsControl page={ page } />
			)}
		</footer>
	);
}