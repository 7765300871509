import styles from '../styles/components/ControllerConnection.module.scss';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { addEmersion, controllerStatus } from '../redux/taskActions';
import { emersionMessage, IP_REGEXP, STORAGE } from '../config';
export const ControllerConnection = () => {
	const dispatch = useDispatch();
	const [ip, setIp] = useState('');
	const [input, setInput] = useState('');
	const [error, setError] = useState(false);
	const controller = useSelector(state => state.task.controller);

	const ipAddressValidation = new RegExp(IP_REGEXP);
	const errorMessage = 'Please Enter Valid IP Address';

	const onInputHandler = (event) => {
		setInput(event.target.value);
	}

	useEffect(() => {
		const localIp = localStorage.getItem(STORAGE.ip);
		
		if (localIp) {
			setIp(localIp);
			dispatch(controllerStatus({ ip: localIp }));
		}
	}, [dispatch, ip]);

	const onControllerHandler = (event) => {
		if (event.type === 'click' || event.key === 'Enter') {
			const value = !input ? controller.ip : input;

			setError(!value.match(ipAddressValidation));

			if (!!value.match(ipAddressValidation)) {
				localStorage.setItem(STORAGE.ip, value);
				setIp(value);
			} else {
				dispatch(addEmersion(emersionMessage(errorMessage, 'error')));
			}
		}
	}

	const classNames = classnames(styles.button, {
		[styles.connected]: controller.status
	});

	return (
		<div className={styles.container}>
			<label className={styles.label}>
				ZIPTRAK IP ID BLIND
				<input className={styles.input}
					   type='text'
					   defaultValue={controller.ip}
					   onKeyUp={onControllerHandler}
					   onInput={onInputHandler}/>
			</label>
			<button className={classNames} onClick={onControllerHandler} >
				{controller.status ? 'Connected' : 'Connect'}
			</button>

			{error && (
				<div className={styles.error}>{errorMessage}</div>
			)}
		</div>
	);
}