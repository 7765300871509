import styles from '../../styles/components/RegisterForm.module.scss';
import { findInputError, isFormInvalid } from '../../utils'
import { useFormContext } from 'react-hook-form'

export const Input = (props) => {
	const { name, type, id, validation, value } = props;
	const { register, formState: { errors } } = useFormContext();
	const inputErrors = findInputError(errors, name);
	const isInvalid = isFormInvalid(inputErrors);

	return (
		<label className={styles.label}>
			<input type={type} name={id} placeholder={name} defaultValue={value}
				   className={styles.input} {...register(name, validation)} />
			{isInvalid && (
				<InputError
					message={inputErrors.error.message}
					key={inputErrors.error.message}
				/>
			)}
	   </label>
	)
}

const InputError = ({ message }) => {
	return (
		<span className={styles.error}>
			{message}
		</span>
	)
}