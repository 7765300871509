import styles from '../../styles/components/Controls.module.scss';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { setGameType } from '../../redux/taskActions';
import { useEffect, useState } from 'react';
import { STORAGE } from '../../config';
import { GetData } from '../../services/ServerData.service';

export const ButtonsGame = ({start}) => {
	const [game, setGame] = useState('');
	const dispatch = useDispatch();
	const buttons = [
		{ id: 'distance', title: 'Distance' },
		{ id: 'battle', title: 'Battle' }
	];

	useEffect(() => {
		const localGameType = localStorage.getItem(STORAGE.gameType);

		if (localGameType) {
			dispatch(setGameType(localGameType));
		}
	}, [dispatch, game])

	const onButtonGameType = (type) => {
		localStorage.setItem(STORAGE.gameType, type);
		setGame(type);

		GetData(type).then(data => {
			console.log(data);
		})
	}

	return (
		<div className={(start ? '' : `${styles.controller} ${styles.right}`)}>
			{!start && (<div className={styles.label}>Games:</div>)}

			{buttons.map((item, index) => (
				<button key={index} onClick={() => onButtonGameType(item.id)}
						className={
							(start
								? classnames(styles[item.id], styles.big)
								: classnames(styles[item.id]))}>
					<span>{item.title}</span>
				</button>
			))}
		</div>
	);
}