import styles from '../../styles/components/RegisterForm.module.scss';
import { Popover } from 'react-tiny-popover';
import { useState } from 'react';
import { Participation } from './Participation';
import { useFormContext } from 'react-hook-form';
import { findInputError, isFormInvalid } from '../../utils';

export const Checkbox = (props) => {
	const { name, type, id, validation, value } = props;
	const { register, formState: { errors } } = useFormContext();
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);

	const onPopoverClose = () => {
		setIsPopoverOpen(false);
	}

	const inputErrors = findInputError(errors, name);
	const isInvalid = isFormInvalid(inputErrors);

	return (
		<div className={styles.checkbox}>
			<input type={type} name={id} placeholder={name} defaultValue={value} {...register(name, validation)} />
			{isInvalid && (
				<InputError
					message={inputErrors.error.message}
					key={inputErrors.error.message}
				/>
			)}

			<Popover isOpen={isPopoverOpen} content={<Participation closePopover={onPopoverClose} />}>
				<div className={styles.checkbox__label} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
					Agree with Participation and Privacy Statement
				</div>
			</Popover>
		</div>
	);
}

const InputError = ({ message }) => {
	return (
		<span className={styles.checkbox__error}>
			{message}
		</span>
	)
}