import styles from '../../styles/components/RegisterForm.module.scss';
export const Participation = ({ closePopover }) => {
	return (
		<>
			<div className={styles.participation}>
				<div className={styles.participation__block}>
					<div className={styles.participation__title}>Participation Disclaimer</div>
					<ul className={styles.participation__list}>
						<li>"The "Ziptrak Sprint Zone" game is one of physical
							exertion, only participate if you are fit and physically able to.
						</li>
						<li>By ticking this box, you assume and accept all risks
							in participating in the Ziptrak® Sprint Zone game. You hereby waive the right to sue Ziptrak
							Pty Ltd, its staff or contractors for any damages, personal injury or death in any way
							whatsoever caused by or relating to the participation in the game to the full extent
							permitted by law.
						</li>
					</ul>
				</div>

				<div className={styles.participation__block}>
					<div className={styles.participation__title}>Privacy Statement</div>
					<ul className={styles.participation__list}>
						<li>Ziptrak Pty Ltd will only collect personal data for
							the purposes of the "Ziptrak® Sprint Zone" game and will delete all information at the end
							of the TDU 23 event. For more information about the Ziptrak Privacy Policy visit <a
								href="http://ziptrak.com.au" target="_blank">www.ziptrak.com.au</a></li>
					</ul>
				</div>
				<div onClick={closePopover} className={styles.participation__close}></div>
			</div>
		</>
	)
}