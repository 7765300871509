import styles from '../../styles/components/RegisterForm.module.scss';
import { findInputError, isFormInvalid } from '../../utils';
import { useFormContext } from 'react-hook-form';

export const Radio = (props) => {
	const { name, type, id, validation, radio } = props;
	const { register, formState: { errors } } = useFormContext();
	const inputErrors = findInputError(errors, name);
	const isInvalid = isFormInvalid(inputErrors);

	return (
		<div className={styles.radio}>
			<span className={styles.radio__title}>{name}:</span>

			{radio && radio.map((item, index) => (
				<label className={styles.radio__label} key={index}>
					<input className={styles.radio__input} type={type} name={id} value={item.value} {...register(name, validation)} />
					{item.name}
				</label>

			))}

			{isInvalid && (
				<InputError
					message={inputErrors.error.message}
					key={inputErrors.error.message}
				/>
			)}
		</div>
	);
}

const InputError = ({ message }) => {
	return (
		<span className={styles.radio__error}>
			{message}
		</span>
	)
}