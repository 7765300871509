import styles from '../styles/Global.module.scss';
import { useSelector } from 'react-redux';
import { RegisterForm } from '../components/RegisterForm';

export const Registration = () => {
	const gameType = useSelector(state => state.task.gameType);

	return (
		<div className={styles.registration}>
			<h1 className={styles.title}>{gameType.toUpperCase()} Registration</h1>
			<p className={styles.description}>To start the race you need to go through a 1 minute registration.</p>

			<RegisterForm />
		</div>
	);
}