import styles from '../../styles/components/Controls.module.scss';
import { ButtonsUpDown } from './ButtonsUpDown';

export const ButtonsControl = ({ page }) => {
	const isRace = page === 'race';

	return (
		<div className={styles.controller}>
			<div className={styles.label}>Controllers:</div>
			{!isRace ? (
				<ButtonsUpDown />
			) : (
				<>
					<button className={styles.start}><span>Start</span></button>
					<button className={styles.stop}><span>Pause</span></button>
					<ButtonsUpDown />
					<button className={styles.reset}><span>Reset</span></button>
				</>
			)}
		</div>
	)
}