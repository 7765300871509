import styles from '../styles/components/RegisterForm.module.scss';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Input } from './forms/Input';
import { FORM_DATA } from '../config';
import { Select } from './forms/Select';
import classnames from 'classnames';
import { Checkbox } from './forms/Checkbox';
import { Radio } from './forms/Radio';

export const RegisterForm = () => {
	const gameType = useSelector(state => state.task.gameType);
	const formData = useForm();

	const onSubmit = formData.handleSubmit(data => {
		console.log(data);

		formData.reset();
	});

	return (
		<FormProvider {...formData}>
			<form className={styles.form} onSubmit={e => e.preventDefault()}
				  noValidate
				  autoComplete="off">

				{FORM_DATA.map((item, index) => (
					<div key={index} className={classnames(styles.block, styles[item.view])}>
						{item.type === 'select' ? (
							<Select {...item} />
						) : item.type === 'checkbox' ? (
							<Checkbox {...item} />
						) : item.type === 'radio' ? (
							<Radio {...item} />
						) : (
							<Input {...item} />
						)}
					</div>
				))}

				<button onClick={onSubmit} className={styles.submit}> Start & Go</button>
			</form>
		</FormProvider>
	);
}