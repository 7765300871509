import styles from '../../styles/components/RegisterForm.module.scss';
import { findInputError, isFormInvalid } from '../../utils'
import { useFormContext } from 'react-hook-form'

export const Select = (props) => {
	const { name, id, validation, age_group, value } = props;
	const { register, formState: { errors } } = useFormContext();
	const inputErrors = findInputError(errors, name);
	const isInvalid = isFormInvalid(inputErrors);

	return (
		<label className={styles.label}>
			<select className={styles.select} name={id} defaultValue={value} {...register(name, validation)}>
				{age_group.map((item, index) => (
					<option key={index} value={item.value}>{item.name}</option>
				))}
			</select>
			{isInvalid && (
				<InputError
					message={inputErrors.error.message}
					key={inputErrors.error.message}
				/>
			)}
		</label>
	)
}

const InputError = ({ message }) => {
	return (
		<span className={styles.error}>
			{message}
		</span>
	)
}