import styles from '../styles/components/Header.module.scss';

import { NavLink } from 'react-router-dom';
import { ConnectionPanel } from './ConnectionPanel';
import { useSelector } from 'react-redux';

export const Header = () => {
	const gameType = useSelector(state => state.task.gameType);

	return (
		<>
			<ConnectionPanel />

			<header className={styles.header}>
				<div className={styles.menu}>
					<NavLink to="/">Games</NavLink>
					<NavLink to="/registration">Registration</NavLink>
					<NavLink to="/race">Race</NavLink>

					{gameType !== 'battle' && (
						<NavLink to="/results">Results</NavLink>
					)}
				</div>
				<div className={styles.menu__logo}>Ziptrak Spring</div>
			</header>
		</>
	);
}