import styles from '../styles/components/BikeConnection.module.scss';
import classnames from 'classnames';
import { bikeConnectionData } from '../config';

export const BikeConnection = (props) => {
	const { status, name, index, bikeStatus } = props;

	const onConnectionHandler = (index) => {
		//TODO DO REACT CONNECTION
		bikeStatus(bikeConnectionData(true, `TEST ${index}`, index));
	}

	const classNames = classnames(styles.button, {
		[styles.connected]: status
	});

	return (
		<div className={styles.container}>
			<label className={styles.label}>
				{`Bike with player #${index + 1}`}
				<button className={classNames} onClick={() => onConnectionHandler(index)}>
					{status ? 'Connected' : 'Connect to a bike'}
				</button>
			</label>
			{name && (
				<div className={styles.description}>
					{`You have connected to the ${name}.`}
				</div>
			)}
		</div>
	);
}