import styles from '../styles/components/Connection.module.scss';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BikeConnection } from './BikeConnection';
import { connectionStatus, setCurrentLink } from '../redux/taskActions';
import { ControllerConnection } from './ControllerConnection';
import { useLocation } from 'react-router';

export const ConnectionPanel = () => {
	const dispatch = useDispatch();
	const [data, setData] = useState('');
	const [active, setActive] = useState(false);
	const location = useLocation();
	const bikeStatusArray = useSelector(state => state.task.bikeStatus);

	const onButtonClick = () => {
		setActive(state => !state);
	}

	const bikeConnectionStatus = (data) => {
		setData(data);
	}

	useEffect(() => {
		const page = location.pathname.split('/')[1];
		const setActiveState = page === 'race' || page === 'results';

		if (data) {
			dispatch(connectionStatus(data));
		}

		dispatch(setCurrentLink(page));
		setActive(!setActiveState);

	}, [data, dispatch, location])

	return (
		<div className={classnames(styles.container, active ? `${styles.active}` : '')}>
			<div className={styles.button__container}>
				<button className={styles.button} onClick={onButtonClick}>
					{bikeStatusArray && bikeStatusArray.map(({ status }, index) => (
						<span key={index} className={
							classnames(styles.bluetooth,
								status ? `${styles.connected}` : `${styles.disconnect}`)}>
							{`Bike ${index + 1}`}
						</span>
					))}
					<span className={styles.button__label}>Connection</span>
				</button>
			</div>

			{active && (
				<div className={styles.content}>
					{bikeStatusArray && bikeStatusArray.map(({ status, name }, index) => (
						<BikeConnection
							key={index}
							status={status}
							name={name}
							index={index}
							bikeStatus={bikeConnectionStatus}
						/>
					))}
					<ControllerConnection />
				</div>
			)}
		</div>
	);
}