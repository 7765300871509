import { BrowserRouter, Route, Routes } from 'react-router-dom';
import classNames from 'classnames';
import styles from '../styles/Global.module.scss';
import { Header } from '../components/Header';
import { Games } from '../pages/Games';
import { Registration } from '../pages/Registration';
import { Race } from '../pages/Race';
import { Results } from '../pages/Results';
import { Controls } from '../components/Controls';
import { Emersion } from '../components/Emersion';
import { useSelector } from 'react-redux';

export const Admin = () => {
	const gameType = useSelector(state => state.task.gameType);

	return (
		<BrowserRouter>
			<div className={classNames(styles.container, styles[gameType])}>
				<Header />
				<div className={styles.content}>
					<Routes>
						<Route exact path="/" element={<Games />} />
						<Route exact path="/registration" element={<Registration />} />
						<Route exact path="/race" element={<Race />} />
						<Route path="/results" element={<Results />} />
					</Routes>
				</div>
				<Controls />
			</div>
			<Emersion />
		</BrowserRouter>
	);
}