import { SERVER_DATA } from '../config';

export const GetData = async (type) => {
	const response = await fetch(SERVER_DATA.getByType(type), {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
		}
	});

	return await response.json();
}

export const PostData = async (data) => {
	const response = await fetch(SERVER_DATA.post, {
		method: 'POST',
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	return await response.json();
}

export const GetController = (ip) => {
	const fetchMethod = (url) => fetch(
		url
	)
	return fetchMethod('http://' + ip);
}

export const GetById = async (id) => {
	const response = await fetch(SERVER_DATA.getById(id), {
		method: 'GET',
		headers: {
			"Content-Type": "application/json",
		}
	});

	return await response.json();
}

export const UpdateData = async (id, data) => {
	const response = await fetch(SERVER_DATA.update(id), {
		method: 'PUT',
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	});

	return await response.json();
}

export const DeleteData = async (id) => {
	const response = await fetch(SERVER_DATA.delete(id), {
		method: 'DELETE',
		headers: {
			"Content-Type": "application/json",
		}
	});

	return await response.json();
}