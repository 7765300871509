import {
	ADD_EMERSION_MESSAGE, ADD_USER_DATA, ADMIN_LINK, CONNECTION_STATUS, CONTROLLER_STATUS, GAME_TYPE,
	REMOVE_ALL_EMISION,
	REMOVE_EMISION_MESSAGE
} from './tasActionTypes';

const initialState = {
	gameType: '',
	currentLink: '/',
	bikeStatus: [{status: false}, {status: false}],
	controller: {
		ip: '192.168.0.119',
		status: false
	},
	emersions: [],
	userData: [],
};

const taskReducer = (state = initialState, action) => {
	switch (action.type) {
		case GAME_TYPE:
			return {
				...state,
				gameType: action.payload
			}
		case CONNECTION_STATUS:
			return {
				...state,
				bikeStatus: state.bikeStatus.map((item, idx) => {
					return idx === action.payload.index ? {...item, ...action.payload} : item
				})
			}
		case CONTROLLER_STATUS:
			return {
				...state,
				controller: {...state.controller, ...action.payload}
			}
		case ADD_EMERSION_MESSAGE:
			return {
				...state,
				emersions: [...state.emersions, action.payload],
			}
		case REMOVE_EMISION_MESSAGE:
			return {
				...state,
				emersions: [...state.emersions.filter((elem, idx) => {
					return idx !== action.payload
				})],
			}
		case REMOVE_ALL_EMISION:
			return {
				...state,
				emersions: [],
			}
		case ADD_USER_DATA:
			return {
				...state,
				userData: [...state.userData, action.payload]
			}
		case ADMIN_LINK:
			return {
				...state,
				currentLink: action.payload
			}
		default:
			return state;
	}
};

export default taskReducer;