import { Admin } from './Folders/Admin';
import { Public } from './Folders/Public';

function App() {
	return (
		<>
			<Admin />
			<Public />
		</>
	);
}

export default App;
